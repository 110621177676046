import { HttpClient, HttpErrorResponse, HttpEvent, HttpParams, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { switchMap, forkJoin, map, of, tap, withLatestFrom, Observable } from 'rxjs';
import { IApplicationState } from 'src/app/app.reducer';
import { environment } from 'src/environments/environment';
import { switchMapCatchErrorOperator } from '../../core/operators/switchmap-catch.operator';
import {
    IAdminDataExportResponseData,
    IAdminDataExportsResponseData,
    IAdminDistributionHelpResponseData,
    IAdminGenericTypesListResponseData,
    IAdminOpportunitiesListResponseData,
    IAdminOpportunityRequestResponseData,
    IAdminOpportunityRequestsResponseData,
    IAdminOpportunityResponseData,
    IAdminProjectResponseData,
    IAdminProjectsResponseData,
    IAdminResponseData,
    IAdminUserCreateResponseData,
    IAdminUserProfileResponseData,
    IAdminUserProfilesResponseData,
    IAdminUserResponseData,
    IAdminUsersResponseData,
} from '../models/admin.model';
import * as AdminActions from './admin.actions';
import {
    fromAdminGenericListResponseToGenericListModel,
    fromOpportunitiesToOpportunityModels,
    fromOpportunityRequestToOpportunityRequestModel,
    fromOpportunityRequestToOpportunityRequestRequestModel,
    fromOpportunityRequestsToOpportunityRequestsModel,
    fromOpportunityToOpportunityModel,
    fromOpportunityToOpportunityCreateRequestModel,
    fromProfileToProfileRequest,
    fromProjectToProjectModel,
    fromProjectToProjectRequest,
    fromProjectsToProjectModels,
    fromUserCreateToUserModel,
    fromUserProfileToUserProfileModel,
    fromUserProfilesToUserProfilesModel,
    fromUserToUserModel,
    fromUsersToUserModels,
    fromOpportunityToOpportunityUpdateRequestModel,
    exportToExcel,
    fromDataExportsToDataExportsModels,
    fromDataExportToDataExportModel,
    fromOpportunityRequestsToOpportunityRequestRequestsModel,
} from './admin.utils';
import { GuidHelper } from '../../core/helpers/guid.helper';
import * as Sentry from '@sentry/angular-ivy';
import { exportSentryErrorMessage, extractErrorFromResponse } from '../../core/helpers/exception.helper';
import { ExceptionArea } from '../../core/models/exception.model';
import { CommonDialogButtonType, CommonDialogComponent, CommonDialogIconType } from '../../shared/components/common-dialog/common-dialog.component';
import { DataExportModel } from '../../core/models/data-export.model';

const handleSwitchMapError = (errorRes: any) => {
    const error = extractErrorFromResponse(errorRes, true);
    return AdminActions.adminFailed({ error: error.error, initialError: error.initialError });
};

@Injectable()
export class AdminEffects {
    constructor(
        private actions: Actions,
        private http: HttpClient,
        private router: Router, // private authService: AuthService, // private store: Store<IApplicationState>,
        public dialog: MatDialog,
        private store: Store<IApplicationState>
    ) {}

    genericTypesGet = createEffect(
        () =>
            this.actions.pipe(
                ofType(AdminActions.genericTypesGet),
                switchMap(() => {
                    console.log('genericTypesGet');
                    const genericTypesGetRequest = this.http.get<IAdminGenericTypesListResponseData>(`${environment.apiUrl}/api/v1/type`);
                    return forkJoin([genericTypesGetRequest]);
                }),
                map(([genericTypesGetResponse]) => {
                    console.log(genericTypesGetResponse);
                    const genericTypes = fromAdminGenericListResponseToGenericListModel(genericTypesGetResponse);
                    return AdminActions.genericTypesGetSuccess({ genericTypes });
                }),
                switchMapCatchErrorOperator(handleSwitchMapError)
            )
        // {dispatch: false}
    );

    genericTypesCreate = createEffect(
        () =>
            this.actions.pipe(
                ofType(AdminActions.genericTypesCreate),
                switchMap(({ genericType }) => {
                    console.log('genericTypesCreate');
                    const genericTypesGetRequestData = { ...genericType.baseType };

                    const genericTypesCreateRequest = this.http.post<IAdminResponseData>(`${environment.apiUrl}/api/v1/type`, genericTypesGetRequestData);
                    return forkJoin([genericTypesCreateRequest]);
                }),
                map(([genericTypesCreateResponse]) => {
                    console.log(genericTypesCreateResponse);
                    return AdminActions.genericTypesGet();
                }),
                switchMapCatchErrorOperator(handleSwitchMapError)
            )
        // {dispatch: false}
    );

    genericTypesUpdate = createEffect(
        () =>
            this.actions.pipe(
                ofType(AdminActions.genericTypesUpdate),
                switchMap(({ genericType }) => {
                    console.log('genericTypesUpdate');
                    const genericTypesGetRequestData = { ...genericType.baseType };
                    delete genericTypesGetRequestData.id;

                    const genericTypesUpdateRequest = this.http.patch<IAdminResponseData>(`${environment.apiUrl}/api/v1/type/${genericType.baseType.id}`, genericTypesGetRequestData);
                    return forkJoin([genericTypesUpdateRequest]);
                }),
                map(([genericTypesUpdateResponse]) => {
                    console.log(genericTypesUpdateResponse);
                    return AdminActions.genericTypesGet();
                }),
                switchMapCatchErrorOperator(handleSwitchMapError)
            )
        // {dispatch: false}
    );

    genericTypesDelete = createEffect(
        () =>
            this.actions.pipe(
                ofType(AdminActions.genericTypesDelete),
                switchMap(({ genericType }) => {
                    console.log('genericTypesDelete');
                    const genericTypesGetRequestData = { ...genericType.baseType };

                    const genericTypesDeleteRequest = this.http.delete<IAdminResponseData>(`${environment.apiUrl}/api/v1/type/${genericType.baseType.id}`);
                    return forkJoin([genericTypesDeleteRequest]);
                }),
                map(([genericTypesDeleteResponse]) => {
                    console.log(genericTypesDeleteResponse);
                    return AdminActions.genericTypesGet();
                }),
                switchMapCatchErrorOperator(handleSwitchMapError)
            )
        // {dispatch: false}
    );

    opportunityCreate = createEffect(
        () =>
            this.actions.pipe(
                ofType(AdminActions.opportunityCreate),
                switchMap(({ opportunity }) => {
                    console.log('opportunityCreate');
                    const opportunityRequestData = fromOpportunityToOpportunityCreateRequestModel(opportunity);

                    const opportunityCreateRequest = this.http.post<IAdminOpportunityResponseData>(
                        `${environment.apiUrl}/api/v1/opportunity?join=addresses,types,suggestionSettings,availableCategories`,
                        opportunityRequestData
                    );
                    return forkJoin([opportunityCreateRequest]);
                }),
                map(([opportunityCreateResponse]) => {
                    console.log(opportunityCreateResponse);
                    const opportunity = fromOpportunityToOpportunityModel(opportunityCreateResponse);
                    return AdminActions.opportunityCreateSuccess({ opportunity });
                }),
                switchMapCatchErrorOperator(handleSwitchMapError)
            )
        // {dispatch: false}
    );

    opportunityUpdate = createEffect(
        () =>
            this.actions.pipe(
                ofType(AdminActions.opportunityUpdate),
                switchMap(({ opportunity }) => {
                    console.log('opportunityUpdate');
                    const opportunityRequestData = fromOpportunityToOpportunityUpdateRequestModel(opportunity);

                    const opportunityUpdateRequest = this.http.patch<IAdminOpportunityResponseData>(
                        `${environment.apiUrl}/api/v1/opportunity/${opportunity.baseOpportunity.id}?join=addresses,types,suggestionSettings,availableCategories,project,filters`,
                        opportunityRequestData
                    );
                    return forkJoin([opportunityUpdateRequest]);
                }),
                map(([opportunityUpdateResponse]) => {
                    const opportunity = fromOpportunityToOpportunityModel(opportunityUpdateResponse);
                    return AdminActions.opportunityUpdateSuccess({ opportunity });
                }),
                switchMapCatchErrorOperator(handleSwitchMapError)
            )
        // {dispatch: false}
    );

    opportunitiesGet = createEffect(
        () =>
            this.actions.pipe(
                ofType(AdminActions.opportunitiesGet),
                switchMap(() => {
                    console.log('opportunitiesGet');
                    const opportunitiesGetRequest = this.http.get<IAdminOpportunitiesListResponseData>(
                        `${environment.apiUrl}/api/v1/opportunity?join=addresses,types,suggestionSettings,availableCategories&limit=100&sort=createdAt|DESC`
                    );
                    return forkJoin([opportunitiesGetRequest]);
                }),
                map(([opportunitiesGetResponse]) => {
                    console.log(opportunitiesGetResponse);
                    const opportunities = fromOpportunitiesToOpportunityModels(opportunitiesGetResponse);
                    return AdminActions.opportunitiesGetSuccess({ opportunities });
                }),
                switchMapCatchErrorOperator(handleSwitchMapError)
            )
        // {dispatch: false}
    );

    opportunityGetOne = createEffect(
        () =>
            this.actions.pipe(
                ofType(AdminActions.opportunityGetOne),
                switchMap(({ opportunityId }) => {
                    console.log('opportunityGetOne');
                    const opportunityGetOneRequest = this.http.get<IAdminOpportunityResponseData>(
                        `${environment.apiUrl}/api/v1/opportunity/${opportunityId}?join=addresses,types,suggestionSettings,availableCategories,project,filters`
                    );
                    return forkJoin([opportunityGetOneRequest]);
                }),
                map(([opportunityGetOneResponse]) => {
                    console.log(opportunityGetOneResponse);
                    const opportunity = fromOpportunityToOpportunityModel(opportunityGetOneResponse);
                    return AdminActions.opportunityGetOneSuccess({ opportunity });
                }),
                switchMapCatchErrorOperator(handleSwitchMapError)
            )
        // {dispatch: false}
    );

    opportunityRequestsGet = createEffect(
        () =>
            this.actions.pipe(
                ofType(AdminActions.opportunityRequestsGet),
                switchMap(({ opportunityId, limit, page, attended, fulfilled, statuses, suggested }) => {
                    console.log('opportunityRequestsGet');
                    let searchQuery = `&sort=profile.lastName|ASC&limit=${limit}&page=${page + 1}`;

                    if (attended) {
                        searchQuery = `${searchQuery}&filter=attendanceDate|$notnull|`;
                    }
                    if (fulfilled) {
                        searchQuery = `${searchQuery}&filter=fulfillType|$eq|fulfilled`;
                    }
                    if (suggested) {
                        searchQuery = `${searchQuery}&filter=suggestion.suggested|$eq|1`;
                    }
                    if (statuses.length) {
                        searchQuery = `${searchQuery}&filter=status|$in|${statuses.join(',')}`;
                    }

                    const opportunityRequestsGetRequest = this.http.get<IAdminOpportunityRequestsResponseData>(
                        `${environment.apiUrl}/api/v1/opportunity/request?filter=opportunityId|$eq|${opportunityId}&join=suggestion,profile,suggestion,profile.addresses${searchQuery}`
                    );
                    return forkJoin([opportunityRequestsGetRequest]);
                }),
                map(([opportunityRequestsGetResponse]) => {
                    console.log(opportunityRequestsGetResponse);
                    const opportunityRequests = fromOpportunityRequestsToOpportunityRequestsModel(opportunityRequestsGetResponse);
                    return AdminActions.opportunityRequestsGetSuccess({ opportunityRequests, opportunityRequestsTotal: opportunityRequestsGetResponse.totalItems });
                }),
                switchMapCatchErrorOperator(handleSwitchMapError)
            )
        // {dispatch: false}
    );

    opportunityRequestsReportGet = createEffect(
        () =>
            this.actions.pipe(
                ofType(AdminActions.opportunityRequestsReportGet),
                switchMap(({ opportunityId, attended, fulfilled, statuses, suggested }) => {
                    console.log('opportunityRequestsReportGet');
                    let searchQuery = `&sort=profile.lastName|ASC&limit=${10000}&page=${1}`;

                    if (attended) {
                        searchQuery = `${searchQuery}&filter=attendanceDate|$notnull|`;
                    }
                    if (fulfilled) {
                        searchQuery = `${searchQuery}&filter=fulfillType|$eq|fulfilled`;
                    }
                    if (suggested) {
                        searchQuery = `${searchQuery}&filter=suggestion.suggested|$eq|1`;
                    }
                    if (statuses.length) {
                        searchQuery = `${searchQuery}&filter=status|$in|${statuses.join(',')}`;
                    }

                    const opportunityRequestsGetRequest = this.http.get<IAdminOpportunityRequestsResponseData>(
                        `${environment.apiUrl}/api/v1/opportunity/request?filter=opportunityId|$eq|${opportunityId}&join=profile,profile.additionalProfiles,profile.addresses${searchQuery}`
                    );
                    return forkJoin([opportunityRequestsGetRequest]);
                }),
                map(([opportunityRequestsGetResponse]) => {
                    console.log(opportunityRequestsGetResponse);
                    const opportunityRequests = fromOpportunityRequestsToOpportunityRequestsModel(opportunityRequestsGetResponse);
                    exportToExcel(opportunityRequests);
                    return AdminActions.opportunityRequestsReportGetSuccess();
                }),
                switchMapCatchErrorOperator(handleSwitchMapError)
            )
        // {dispatch: false}
    );

    opportunityRequestGet = createEffect(
        () =>
            this.actions.pipe(
                ofType(AdminActions.opportunityRequestGet),
                switchMap(({ opportunityRequestId }) => {
                    console.log('opportunityRequestGet');
                    const opportunityRequestGetRequest = this.http.get<IAdminOpportunityRequestResponseData>(
                        `${environment.apiUrl}/api/v1/opportunity/request/${opportunityRequestId}?join=profile,opportunity,suggestion`
                    );
                    return forkJoin([opportunityRequestGetRequest]);
                }),
                switchMap(([opportunityRequestGetResponse]) => {
                    console.log('opportunityRequestGet');
                    const userProfileGetRequest = this.http.get<IAdminUserProfileResponseData>(
                        `${environment.apiUrl}/api/v1/profile/${opportunityRequestGetResponse.profileId}?join=addresses,additionalProfiles`
                    );
                    return forkJoin([of(opportunityRequestGetResponse), userProfileGetRequest]);
                }),
                map(([opportunityRequestGetResponse, userProfileGetResponse]) => {
                    console.log(opportunityRequestGetResponse);
                    const profile = fromUserProfileToUserProfileModel(userProfileGetResponse);
                    opportunityRequestGetResponse.profile = profile;
                    const opportunityRequest = fromOpportunityRequestToOpportunityRequestModel(opportunityRequestGetResponse);
                    return AdminActions.opportunityRequestGetSuccess({ opportunityRequest });
                }),
                switchMapCatchErrorOperator(handleSwitchMapError)
            )
        // {dispatch: false}
    );

    userProfileGet = createEffect(
        () =>
            this.actions.pipe(
                ofType(AdminActions.userProfileGet),
                switchMap(({ profileId }) => {
                    console.log('userProfileGet');
                    const userProfileGetRequest = this.http.get<IAdminUserProfileResponseData>(`${environment.apiUrl}/api/v1/profile/${profileId}?join=addresses,additionalProfiles`);
                    return forkJoin([userProfileGetRequest]);
                }),
                map(([userProfileGetRequestResponse]) => {
                    console.log(userProfileGetRequestResponse);
                    const profile = fromUserProfileToUserProfileModel(userProfileGetRequestResponse);
                    return AdminActions.userProfileGetSuccess({ profile });
                }),
                switchMapCatchErrorOperator(handleSwitchMapError)
            )
        // {dispatch: false}
    );

    opportunityRequestUpdate = createEffect(
        () =>
            this.actions.pipe(
                ofType(AdminActions.opportunityRequestUpdate),
                switchMap(({ opportunityRequest }) => {
                    console.log('opportunityRequestUpdate');
                    const opportunityRequestData = fromOpportunityRequestToOpportunityRequestRequestModel(opportunityRequest);

                    const opportunityRequestUpdateRequest = this.http.patch<IAdminOpportunityRequestResponseData>(
                        `${environment.apiUrl}/api/v1/opportunity/request/${opportunityRequest.getId()}?join=profile,opportunity,suggestion,profile.addresses`,
                        opportunityRequestData
                    );
                    return forkJoin([opportunityRequestUpdateRequest]);
                }),
                map(([opportunityRequestUpdateResponse]) => {
                    console.log(opportunityRequestUpdateResponse);
                    const opportunityRequest = fromOpportunityRequestToOpportunityRequestModel(opportunityRequestUpdateResponse);
                    return AdminActions.opportunityRequestUpdateSuccess({ opportunityRequest });
                }),
                switchMapCatchErrorOperator(handleSwitchMapError)
            )
        // {dispatch: false}
    );

    opportunityRequestMassUpdate = createEffect(
        () =>
            this.actions.pipe(
                ofType(AdminActions.opportunityRequestMassUpdate),
                switchMap(({ opportunityRequests }) => {
                    console.log('opportunityRequestMassUpdate');
                    const opportunityRequestsData = fromOpportunityRequestsToOpportunityRequestRequestsModel(opportunityRequests);

                    const opportunityRequestsUpdateRequest = this.http.patch<IAdminOpportunityRequestResponseData[]>(
                        `${environment.apiUrl}/api/v1/opportunity/request/bulk?join=profile,opportunity,suggestion,profile.addresses`,
                        { items: opportunityRequestsData }
                    );
                    return forkJoin([opportunityRequestsUpdateRequest]);
                }),
                map(([opportunityRequestsUpdateResponse]) => {
                    console.log(opportunityRequestsUpdateResponse);
                    const responseData = {
                        items: opportunityRequestsUpdateResponse,
                        totalItems: 0,
                        currentPage: 0,
                        pageLimit: 0,
                        totalPages: 0,
                    };
                    const opportunityRequests = fromOpportunityRequestsToOpportunityRequestsModel(responseData);
                    return AdminActions.opportunityRequestMassUpdateSuccess({ opportunityRequests });
                }),
                switchMapCatchErrorOperator(handleSwitchMapError)
            )
        // {dispatch: false}
    );

    opportunityRequestDelete = createEffect(
        () =>
            this.actions.pipe(
                ofType(AdminActions.opportunityRequestDelete),
                switchMap(({ opportunityRequest }) => {
                    console.log('opportunityRequestDelete');
                    const opportunityRequestDeleteRequest = this.http.delete<IAdminOpportunityRequestResponseData>(
                        `${environment.apiUrl}/api/v1/opportunity/request/${opportunityRequest.getId()}/permanent`
                    );

                    return forkJoin([opportunityRequestDeleteRequest]);
                }),
                map(([opportunityRequestDeleteResponse]) => {
                    console.log(opportunityRequestDeleteResponse);
                    const opportunityRequest = fromOpportunityRequestToOpportunityRequestModel(opportunityRequestDeleteResponse);
                    return AdminActions.opportunityRequestDeleteSuccess({ opportunityRequest });
                }),
                switchMapCatchErrorOperator(handleSwitchMapError)
            )
        // {dispatch: false}
    );

    usersProfilesGet = createEffect(
        () =>
            this.actions.pipe(
                ofType(AdminActions.usersProfilesGet),
                switchMap(({ roles, searchPattern }) => {
                    console.log('usersProfilesGet');
                    const userProfilesGetRequest = this.http.get<IAdminUserProfilesResponseData>(`${environment.apiUrl}/api/v1/profile?join=addresses,additionalProfiles`);
                    return forkJoin([userProfilesGetRequest]);
                }),
                map(([usersProfilesGetResponse]) => {
                    console.log(usersProfilesGetResponse);
                    const usersProfiles = fromUserProfilesToUserProfilesModel(usersProfilesGetResponse);
                    return AdminActions.usersProfilesGetSuccess({ usersProfiles });
                }),
                switchMapCatchErrorOperator(handleSwitchMapError)
            )
        // {dispatch: false}
    );

    userGet = createEffect(
        () =>
            this.actions.pipe(
                ofType(AdminActions.userGet),
                switchMap(({ userId }) => {
                    console.log('userGet');
                    const userGetRequest = this.http.get<IAdminUserResponseData>(`${environment.apiUrl}/api/v1/user/${userId}`);
                    return forkJoin([of(userId), userGetRequest]);
                }),
                switchMap(([userId, userGetResponse]) => {
                    console.log('userProfileGet');
                    const userProfileGetRequest = this.http.get<IAdminUserProfileResponseData>(`${environment.apiUrl}/api/v1/profile/${userId}?join=addresses,additionalProfiles`);
                    return forkJoin([of(userGetResponse), userProfileGetRequest]);
                }),
                map(([userGetResponse, userProfileGetResponse]) => {
                    console.log(userGetResponse);
                    const user = fromUserToUserModel(userGetResponse);
                    const profile = fromUserProfileToUserProfileModel(userProfileGetResponse);
                    user.setProfile(profile);
                    return AdminActions.userGetSuccess({ user });
                }),
                switchMapCatchErrorOperator(handleSwitchMapError)
            )
        // {dispatch: false}
    );

    usersGet = createEffect(
        () =>
            this.actions.pipe(
                ofType(AdminActions.usersGet),
                switchMap(({ statuses, searchPattern, limit, page, sortDirection, sortField }) => {
                    console.log('usersGet');
                    let searchQuery = `&sort=${sortField}|${sortDirection}&limit=${limit}&page=${page + 1}`;

                    if (searchPattern) {
                        searchQuery = `${searchQuery}&filter=profile.firstName|$cont|${searchPattern}&or=profile.lastName|$cont|${searchPattern}&or=profile.email|$cont|${searchPattern}&or=profile.phone|$cont|${searchPattern}&or=profile.passport|$eq|${searchPattern}&or=profile.temporaryId|$eq|${searchPattern}&or=profile.additionalProfiles.passport|$eq|${searchPattern}}&or=profile.additionalProfiles.passport|$eq|${searchPattern}&or=profile.additionalProfiles.temporaryId|$eq|${searchPattern}`;
                    }
                    if (statuses.length) {
                        searchQuery = `${searchQuery}&filter=status|$in|${statuses.join(',')}`;
                    }

                    const usersGetRequest = this.http.get<IAdminUsersResponseData>(`${environment.apiUrl}/api/v1/user?join=profile${searchQuery}`);
                    return forkJoin([usersGetRequest]);
                }),
                map(([usersGetResponse]) => {
                    console.log(usersGetResponse);
                    const users = fromUsersToUserModels(usersGetResponse);
                    return AdminActions.usersGetSuccess({ users, usersTotal: usersGetResponse.totalItems });
                }),
                switchMapCatchErrorOperator(handleSwitchMapError)
            )
        // {dispatch: false}
    );

    userUpdate = createEffect(
        () =>
            this.actions.pipe(
                ofType(AdminActions.userUpdate),
                switchMap(({ roles, userId, status }) => {
                    console.log('userUpdate');
                    const userUpdateData = {
                        roles,
                        status,
                    };

                    const userUpdateRequest = this.http.patch<IAdminUserResponseData>(`${environment.apiUrl}/api/v1/user/${userId}`, userUpdateData);
                    return forkJoin([userUpdateRequest]);
                }),
                map(([userUpdateResponse]) => {
                    console.log(userUpdateResponse);
                    const user = fromUserToUserModel(userUpdateResponse);
                    return AdminActions.userUpdateSuccess({ user });
                }),
                switchMapCatchErrorOperator(handleSwitchMapError)
            )
        // {dispatch: false}
    );

    userProfileUpdate = createEffect(
        () =>
            this.actions.pipe(
                ofType(AdminActions.userProfileUpdate),
                switchMap(({ profile }) => {
                    console.log('userProfileUpdate');
                    const profleRequest = fromProfileToProfileRequest(profile);
                    const profileUpdateRequestData = {
                        ...profleRequest,
                    };

                    const profileUpdateRequest = this.http.patch<IAdminUserProfileResponseData>(
                        `${environment.apiUrl}/api/v1/profile/${profile.id}?join=addresses,additionalProfiles`,
                        profileUpdateRequestData
                    );
                    return forkJoin([profileUpdateRequest]);
                }),
                map(([userProfileUpdateResponse]) => {
                    console.log(userProfileUpdateResponse);
                    const profile = fromUserProfileToUserProfileModel(userProfileUpdateResponse);
                    return AdminActions.userProfileUpdateSuccess({ profile });
                }),
                switchMapCatchErrorOperator(handleSwitchMapError)
            )
        // {dispatch: false}
    );

    userResetPassword = createEffect(
        () =>
            this.actions.pipe(
                ofType(AdminActions.userResetPassword),
                switchMap(({ userId, password }) => {
                    console.log('userResetPassword');
                    const userResetPasswordData = {
                        password,
                        confirmPassword: password,
                    };

                    const userResetPasswordRequest = this.http.put<IAdminUserResponseData>(`${environment.apiUrl}/api/v1/user/${userId}/reset-password`, userResetPasswordData);
                    return forkJoin([userResetPasswordRequest]);
                }),
                map(([userResetPasswordResponse]) => {
                    console.log(userResetPasswordResponse);
                    return AdminActions.userResetPasswordSuccess();
                }),
                switchMapCatchErrorOperator(handleSwitchMapError)
            )
        // {dispatch: false}
    );

    userCreate = createEffect(
        () =>
            this.actions.pipe(
                ofType(AdminActions.userCreate),
                switchMap(({ email, password }) => {
                    console.log('userCreate');
                    const userCreateData = {
                        password,
                        email,
                        firstName: '',
                        lastName: '',
                    };

                    const userCreateRequest = this.http.post<IAdminUserCreateResponseData>(`${environment.apiUrl}/api/v1/user`, userCreateData);
                    return forkJoin([userCreateRequest]);
                }),
                map(([userCreateResponse]) => {
                    console.log(userCreateResponse);
                    const user = fromUserCreateToUserModel(userCreateResponse);
                    return AdminActions.userCreateSuccess({ user });
                }),
                switchMapCatchErrorOperator(handleSwitchMapError)
            )
        // {dispatch: false}
    );

    userCreateSuccess = createEffect(
        () =>
            this.actions.pipe(
                ofType(AdminActions.userCreateSuccess),
                tap(({ user }) => {
                    setTimeout(() => {
                        this.router.navigate(['admin', 'users', user.baseUser.id]);
                    }, 2000);
                })
            ),
        { dispatch: false }
    );

    opportunityRequestsGetByUser = createEffect(
        () =>
            this.actions.pipe(
                ofType(AdminActions.opportunityRequestsGetByUser),
                switchMap(({ userId }) => {
                    console.log('opportunityRequestsGetByUser');
                    const opportunityRequestsGetByUserRequest = this.http.get<IAdminOpportunityRequestsResponseData>(
                        `${environment.apiUrl}/api/v1/opportunity/request?filter=profileId|$eq|${userId}&join=opportunity,suggestion`
                    );
                    return forkJoin([opportunityRequestsGetByUserRequest]);
                }),
                map(([opportunityRequestsGetByUserResponse]) => {
                    console.log(opportunityRequestsGetByUserResponse);
                    const opportunityRequests = fromOpportunityRequestsToOpportunityRequestsModel(opportunityRequestsGetByUserResponse);
                    return AdminActions.opportunityRequestsGetByUserSuccess({ opportunityRequests });
                }),
                switchMapCatchErrorOperator(handleSwitchMapError)
            )
        // {dispatch: false}
    );

    projectGet = createEffect(
        () =>
            this.actions.pipe(
                ofType(AdminActions.projectGet),
                switchMap(({ projectId }) => {
                    console.log('projectGet');
                    const projectGetRequest = this.http.get<IAdminProjectResponseData>(`${environment.apiUrl}/api/v1/project/${projectId}?join=opportunities`);
                    return forkJoin([projectGetRequest]);
                }),
                switchMap(([projectGetResponse]) => {
                    console.log('projectGet');
                    const project = fromProjectToProjectModel(projectGetResponse);
                    const opportunitiesIds =
                        project
                            .getOpportunities()
                            .map((o) => o.baseOpportunity.id)
                            .join(',') || GuidHelper.GetEmptyGuid();
                    const opportunityRequestsGetRequest = this.http.get<IAdminOpportunityRequestsResponseData>(
                        `${environment.apiUrl}/api/v1/opportunity/request?filter=opportunityId|$in|${opportunitiesIds}&filter=fulfillType|$eq|fulfilled&join=profile,suggestion,profile.addresses`
                    );
                    return forkJoin([of(project), opportunityRequestsGetRequest]);
                }),
                map(([project, opportunityRequestsGetResponse]) => {
                    const opportunityRequests = fromOpportunityRequestsToOpportunityRequestsModel(opportunityRequestsGetResponse);
                    project.getOpportunities().forEach((o) => {
                        const oppRequests = opportunityRequests.filter((or) => or.baseRequest.opportunityId === o.baseOpportunity.id);
                        if (oppRequests.length) {
                            o.setOpportunityRequests(oppRequests);
                        }
                    });
                    console.log('full project', project);
                    // const project = fromProjectToProjectModel(projectGetResponse);
                    return AdminActions.projectGetSuccess({ project });
                }),
                switchMapCatchErrorOperator(handleSwitchMapError)
            )
        // {dispatch: false}
    );

    projectsGet = createEffect(
        () =>
            this.actions.pipe(
                ofType(AdminActions.projectsGet),
                switchMap(() => {
                    console.log('projectsGet');
                    const projectsGetRequest = this.http.get<IAdminProjectsResponseData>(`${environment.apiUrl}/api/v1/project?join=opportunities`);
                    return forkJoin([projectsGetRequest]);
                }),
                map(([projectsGetResponse]) => {
                    console.log(projectsGetResponse);
                    const projects = fromProjectsToProjectModels(projectsGetResponse);
                    return AdminActions.projectsGetSuccess({ projects });
                }),
                switchMapCatchErrorOperator(handleSwitchMapError)
            )
        // {dispatch: false}
    );

    projectUpdate = createEffect(
        () =>
            this.actions.pipe(
                ofType(AdminActions.projectUpdate),
                switchMap(({ project }) => {
                    console.log('projectUpdate');
                    const projectData = fromProjectToProjectRequest(project.baseProject);
                    const projectUpdateRequest = this.http.patch<IAdminProjectResponseData>(`${environment.apiUrl}/api/v1/project/${project.baseProject.id}`, projectData);
                    return forkJoin([projectUpdateRequest]);
                }),
                map(([projectUpdateResponse]) => {
                    console.log(projectUpdateResponse);
                    const project = fromProjectToProjectModel(projectUpdateResponse);
                    return AdminActions.projectUpdateSuccess({ project });
                }),
                switchMapCatchErrorOperator(handleSwitchMapError)
            )
        // {dispatch: false}
    );

    projectCreate = createEffect(
        () =>
            this.actions.pipe(
                ofType(AdminActions.projectCreate),
                switchMap(({ project }) => {
                    console.log('projectCreate');
                    const projectData = fromProjectToProjectRequest(project.baseProject);
                    const projectCreateRequest = this.http.post<IAdminProjectResponseData>(`${environment.apiUrl}/api/v1/project`, projectData);
                    return forkJoin([projectCreateRequest]);
                }),
                map(([projectCreateResponse]) => {
                    console.log(projectCreateResponse);
                    const project = fromProjectToProjectModel(projectCreateResponse);
                    return AdminActions.projectCreateSuccess({ project });
                }),
                switchMapCatchErrorOperator(handleSwitchMapError)
            )
        // {dispatch: false}
    );

    projectCreateSuccess = createEffect(
        () =>
            this.actions.pipe(
                ofType(AdminActions.projectCreateSuccess),
                tap(({ project }) => {
                    setTimeout(() => {
                        this.router.navigate(['admin', 'projects', project.baseProject.id]);
                    }, 2000);
                })
            ),
        { dispatch: false }
    );

    opportunityCreateSuccess = createEffect(
        () =>
            this.actions.pipe(
                ofType(AdminActions.opportunityCreateSuccess),
                tap(({ opportunity }) => {
                    setTimeout(() => {
                        this.router.navigate(['admin', 'opportunity', opportunity.baseOpportunity.id]);
                    }, 2000);
                })
            ),
        { dispatch: false }
    );

    dataExportGenerate = createEffect(
        () =>
            this.actions.pipe(
                ofType(AdminActions.dataExportGenerate),
                switchMap(({ opportunityId }) => {
                    console.log('dataExportGenerate');
                    let queryString = '?join=additionalProfiles,addresses&entity=profile&filter=parentId|$isnull|';
                    if (opportunityId) {
                        queryString = `?join=profile,profile.additionalProfiles,profile.addresses&entity=opportunity-request&&filter=opportunityId|$eq|${opportunityId}`;
                    }

                    const dataExportGetRequest = this.http.get<IAdminDataExportResponseData>(`${environment.apiUrl}/api/v1/export${queryString}`);
                    return forkJoin([dataExportGetRequest]);
                }),
                switchMap(() => {
                    console.log('dataExportsGet');
                    const dataExportsGetRequest = this.http.get<IAdminDataExportsResponseData>(`${environment.apiUrl}/api/v1/export/list`);
                    return forkJoin([dataExportsGetRequest]);
                }),
                map(([dataExportsGetResponse]) => {
                    console.log(dataExportsGetResponse);
                    const dataExports = fromDataExportsToDataExportsModels(dataExportsGetResponse);
                    return AdminActions.dataExportsGetSuccess({ dataExports });
                }),
                switchMapCatchErrorOperator(handleSwitchMapError)
            )
        // {dispatch: false}
    );

    dataExportDelete = createEffect(
        () =>
            this.actions.pipe(
                ofType(AdminActions.dataExportDelete),
                switchMap(({ dataExportId }) => {
                    console.log('dataExportDelete');
                    const dataExportGetRequest = this.http.delete<IAdminDataExportResponseData>(`${environment.apiUrl}/api/v1/export/${dataExportId}`);
                    return forkJoin([dataExportGetRequest]);
                }),
                switchMap(() => {
                    console.log('dataExportsGet');
                    const dataExportsGetRequest = this.http.get<IAdminDataExportsResponseData>(`${environment.apiUrl}/api/v1/export/list`);
                    return forkJoin([dataExportsGetRequest]);
                }),
                map(([dataExportsGetResponse]) => {
                    console.log(dataExportsGetResponse);
                    const dataExports = fromDataExportsToDataExportsModels(dataExportsGetResponse);
                    return AdminActions.dataExportsGetSuccess({ dataExports });
                }),
                switchMapCatchErrorOperator(handleSwitchMapError)
            )
        // {dispatch: false}
    );

    dataExportGet = createEffect(
        () =>
            this.actions.pipe(
                ofType(AdminActions.dataExportGet),
                switchMap(({ dataExportId }) => {
                    console.log('dataExportGet');
                    const dataExportGetRequest = this.http.get(`${environment.apiUrl}/api/v1/export/${dataExportId}/csv`, { responseType: 'arraybuffer' });
                    return forkJoin([dataExportGetRequest]);
                }),
                map(([dataExportGetResponse]) => {
                    console.log(dataExportGetResponse);
                    const blob = new Blob([dataExportGetResponse], { type: 'attachment;filename=report.csv' });
                    const url = window.URL.createObjectURL(blob);
                    const urlElement = document.createElement('a');
                    document.body.appendChild(urlElement);
                    urlElement.setAttribute('style', 'display: none');
                    urlElement.href = url;
                    urlElement.download = 'report.csv';
                    urlElement.click();
                    window.URL.revokeObjectURL(url);
                    urlElement.remove();
                    return AdminActions.dataExportGetSuccess({ dataExport: new DataExportModel() });
                }),
                switchMapCatchErrorOperator(handleSwitchMapError)
            )
        // {dispatch: false}
    );

    dataExportsGet = createEffect(
        () =>
            this.actions.pipe(
                ofType(AdminActions.dataExportsGet),
                switchMap(() => {
                    console.log('dataExportsGet');
                    const dataExportsGetRequest = this.http.get<IAdminDataExportsResponseData>(`${environment.apiUrl}/api/v1/export/list?sort=createdAt|DESC`);
                    return forkJoin([dataExportsGetRequest]);
                }),
                map(([dataExportsGetResponse]) => {
                    console.log(dataExportsGetResponse);
                    const dataExports = fromDataExportsToDataExportsModels(dataExportsGetResponse);
                    return AdminActions.dataExportsGetSuccess({ dataExports });
                }),
                switchMapCatchErrorOperator(handleSwitchMapError)
            )
        // {dispatch: false}
    );

    adminFailed = createEffect(
        () =>
            this.actions.pipe(
                ofType(AdminActions.adminFailed),
                withLatestFrom(this.store.select('auth')),
                tap(([action, { user }]) => {
                    const errorMessage = exportSentryErrorMessage(action, user, ExceptionArea.admin);
                    Sentry.captureException(JSON.stringify(errorMessage));
                })
            ),
        { dispatch: false }
    );

    opportunitySuggestmentHelpGet = createEffect(
        () =>
            this.actions.pipe(
                ofType(AdminActions.opportunitySuggestmentHelpGet),
                switchMap((props) => {
                    const { opportunityId } = props;
                    console.log('opportunityRequestsGet.opportunitySuggestmentHelpGet');
                    const opportunitySuggestmentHelpGetRequest = this.http.post<IAdminDistributionHelpResponseData>(
                        `${environment.apiUrl}/api/v1/opportunity/${opportunityId}/apply-distribution-help`,
                        {}
                    );
                    return forkJoin([of(props), opportunitySuggestmentHelpGetRequest]);
                }),
                switchMap(([props, response]) => {
                    const { opportunityId, limit, page, attended, fulfilled, statuses, suggested } = props;
                    console.log('opportunityRequestsGet');
                    let searchQuery = `&sort=profile.lastName|ASC&limit=${limit}&page=${page + 1}`;

                    if (attended) {
                        searchQuery = `${searchQuery}&filter=attendanceDate|$notnull|`;
                    }
                    if (fulfilled) {
                        searchQuery = `${searchQuery}&filter=fulfillType|$eq|fulfilled`;
                    }
                    if (suggested) {
                        searchQuery = `${searchQuery}&filter=suggestion.suggested|$eq|1`;
                    }
                    if (statuses.length) {
                        searchQuery = `${searchQuery}&and=status|$in|${statuses.join(',')}`;
                    }

                    const opportunityRequestsGetRequest = this.http.get<IAdminOpportunityRequestsResponseData>(
                        `${environment.apiUrl}/api/v1/opportunity/request?filter=opportunityId|$eq|${opportunityId}&join=profile,suggestion,profile.addresses${searchQuery}`
                    );
                    return forkJoin([opportunityRequestsGetRequest, of(response)]);
                }),
                map(([opportunityRequestsGetResponse, helpDirubutionResponse]) => {
                    console.log(opportunityRequestsGetResponse);

                    this.store.dispatch(AdminActions.opportunitySuggestmentHelpGetSuccess({ gini: helpDirubutionResponse.gini }));
                    const opportunityRequests = fromOpportunityRequestsToOpportunityRequestsModel(opportunityRequestsGetResponse);
                    return AdminActions.opportunityRequestsGetSuccess({ opportunityRequests, opportunityRequestsTotal: opportunityRequestsGetResponse.totalItems });
                }),
                switchMapCatchErrorOperator(handleSwitchMapError)
            )
        // {dispatch: false}
    );

    opportunitySuggestmentHelpGetSuccess = createEffect(
        () =>
            this.actions.pipe(
                ofType(AdminActions.opportunitySuggestmentHelpGetSuccess),
                map((action) => {
                    this.dialog.open(CommonDialogComponent, {
                        data: {
                            header: 'Розподіл допомоги',
                            content: `Позподіл допомоги здійснено успішно. Значення якості: ${action.gini} `,
                            icon: CommonDialogIconType.Success,
                            buttonType: CommonDialogButtonType.Text,
                            actionText: 'Закрити',
                        },
                    });
                })
            ),
        { dispatch: false }
    );
}
