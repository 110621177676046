<div class="opportunity container">
    <app-breadcrumps
        [currentItem]="opportunity.baseOpportunity.name"
        [rootItems]="[{ text: 'Події', href: '/client/opportunities' }]"></app-breadcrumps>
    <h2>{{ opportunity.baseOpportunity.name }}</h2>
    <app-error-message
        *ngIf="error"
        [errorMessage]="error"></app-error-message>
    <div *ngIf="isFullfilled()">
        <app-notification-message
            [notificationType]="'success'"
            [notificationMessage]="'Допомогу отримано.'"></app-notification-message>
    </div>
    <div *ngIf="!isFullfilled()">
        <app-notification-message
            *ngIf="isStateSent()"
            [notificationType]="'success'"
            [notificationMessage]="'Ваша заявка була успішно подана, слідкуйте за її статусом у розділі Заявки на події.'"></app-notification-message>
        <app-notification-message
            *ngIf="isStateApprovedAndNotCofirfirmed()"
            [notificationType]="'success'"
            [notificationMessage]="'Вашу заявку схвалено, слідкуйте за деталями проведення події.'"></app-notification-message>
        <app-notification-message
            *ngIf="isStateApprovedAndCofirfirmed()"
            [notificationType]="'success'"
            [notificationMessage]="'Очікуємо вас за вказаним часом.'"></app-notification-message>
        <app-notification-message
            *ngIf="isStateRejected()"
            [notificationType]="'warning'"
            [notificationMessage]="'Вашу заявку відхилено'"></app-notification-message>
        <app-notification-message
            *ngIf="isStateCanceled()"
            [notificationType]="'warning'"
            [notificationMessage]="'Вашу заявку скасовано'"></app-notification-message>
    </div>

    <div class="opportunity-wrapper">
        <div
            fxLayout="row"
            fxLayout.xs="column">
            <div class="opportunity-left">
                <div class="opportunity__image">
                    <div class="opportunity__status">{{ this.opportunity.baseOpportunity.status | translateEnum : 'OpportunityStatus' }}</div>
                </div>
                <div class="opportunity__date">
                    <h4 class="opportunity__date-title">Період реєстрації на подію</h4>
                    <div
                        fxLayout="row"
                        fxLayoutAlign="space-evenly">
                        <div class="opportunity__date-item-wrapper">
                            <span class="opportunity__date-sub-title">Початок</span>
                            <div class="opportunity__date-item">
                                {{ opportunity.baseOpportunity.visible.startDate | date : 'mediumDate' }}
                                <mat-icon class="opportunity__date-icon">date_range</mat-icon>
                            </div>
                        </div>
                        <div class="opportunity__date-divider">&mdash;</div>
                        <div class="opportunity__date-item-wrapper">
                            <span class="opportunity__date-sub-title">Завершення</span>
                            <div class="opportunity__date-item">
                                {{ opportunity.baseOpportunity.visible.endDate | date : 'mediumDate' }}
                                <mat-icon class="opportunity__date-icon">date_range</mat-icon>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="opportunity__actions"
                    *ngIf="!isFullfilled()">
                    <div
                        fxLayout="row"
                        *ngIf="isStateApprovedAndNotCofirfirmed()">
                        <div class="opportunity__button-confirm">
                            <h4 class="opportunity__button-confirm-title">Вашу заявку схвалено</h4>
                            <div class="opportunity__button-confirm-subtitle">Необхідно підтвердити участь</div>
                            <button
                                (click)="onConfirmPresence()"
                                mat-flat-button
                                color="primary"
                                class="opportunity__button opportunity__button--visible button-green">
                                Підтвердити
                            </button>
                        </div>
                    </div>
                    <div
                        fxLayout="row"
                        *ngIf="isCanSendRequest()">
                        <button
                            mat-flat-button
                            color="primary"
                            class="opportunity__button opportunity__button--visible"
                            (click)="onSubmitOpportunityRequest()">
                            Подати заявку
                        </button>
                    </div>
                    <div
                        fxLayout="row"
                        *ngIf="!isOpportunityConditionsMet()">
                        <div class="opportunity__condition-container">
                            <h4 class="opportunity__conditions-warning">На жаль, ви не можете зареєструватись на дану акцію в зв’язку з тим, що вона не охоплює ваш регіон. </h4>
                            Проте наша команда працює над тим, щоб охоплювати допомогою більше людей. Тому стежте, будь ласка, за оновленням акцій на нашому порталі і обов’язково реєструйтесь в майбутніх подіях. 
                        </div>
                    </div>
                    <div
                        fxLayout="row"
                        *ngIf="isCanCancelRequest()">
                        <button
                            (click)="onCancelRequest()"
                            mat-flat-button
                            color="accent"
                            class="opportunity__button opportunity__button--visible">
                            Скасувати заявку
                        </button>
                    </div>
                </div>
            </div>
            <div class="opportunity-right">
                <mat-expansion-panel
                    *ngIf="isStatCanSeeInnerDetails()"
                    hideToggle
                    class="opportunity__expansion"
                    expanded="true">
                    <mat-expansion-panel-header>
                        <mat-panel-title class="opportunity__expansion-title">Деталі, щодо отримання допомоги</mat-panel-title>
                    </mat-expansion-panel-header>
                    <div
                        fxLayout="row"
                        fxLayout.xs="column"
                        fxLayoutAlign="space-between">
                        <div class="opportunity__active-item">
                            <div class="opportunity__active-item-title">Дата</div>
                            <div class="opportunity__active-item-subtitle">Дати</div>
                            <div class="opportunity__active-item-date">
                                {{ opportunity.baseOpportunity.active.startDate | date : 'mediumDate' }}&nbsp;&mdash;&nbsp;{{ opportunity.baseOpportunity.active.endDate | date : 'mediumDate' }}
                            </div>
                        </div>
                        <div class="opportunity__active-item">
                            <div class="opportunity__active-item-title">Інформація</div>
                            <div class="opportunity__active-item-subtitle">Інформація стасовно місця події.</div>
                            <div [innerHTML]="this.opportunity.baseOpportunity.activeDescription"></div>
                        </div>
                    </div>
                    <div
                        fxLayout="row"
                        fxLayout.xs="column"
                        fxLayoutAlign="space-between">
                        <div class="opportunity__active-item">
                            <div class="opportunity__active-item-title">Дата</div>
                            <div class="opportunity__active-item-subtitle">Отримайте допомогу цього числа.</div>
                            <div class="opportunity__active-item-number">{{ getOppotunityRequest()!.baseRequest.orderId }}</div>
                        </div>
                        <div class="opportunity__active-item">
                            <div class="opportunity__active-item-title">QR Code</div>
                            <div class="opportunity__active-item-subtitle">Покажіть QR-код під час отримання допомги.</div>
                            <div class="opportunity__active-item-qrcode">
                                <!-- {{ getQRCodeUrl() }} -->
                                <qrcode
                                    [qrdata]="getQRCodeUrl()"
                                    [width]="206"
                                    [errorCorrectionLevel]="'M'"
                                    [allowEmptyString]="true"></qrcode>
                            </div>
                        </div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel
                    hideToggle
                    class="opportunity__expansion"
                    expanded="true">
                    <mat-expansion-panel-header>
                        <mat-panel-title class="opportunity__expansion-title">Для кого</mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="opportunity__option">
                        <div class="opportunity__option-header">Тип допомоги</div>
                        <div fxLayout="row">
                            <div
                                class="opportunity__option-item"
                                *ngFor="let item of this.opportunity.baseOpportunity.types">
                                {{ item.baseType.name }}
                            </div>
                        </div>
                    </div>
                    <div class="opportunity__option">
                        <div class="opportunity__option-header">Категорія допомоги</div>
                        <div fxLayout="row">
                            <div
                                class="opportunity__option-item"
                                *ngFor="let item of this.opportunity.baseOpportunity.availableCategories">
                                {{ item.baseType.name }}
                            </div>
                        </div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel
                    hideToggle
                    class="opportunity__expansion"
                    expanded="true">
                    <mat-expansion-panel-header>
                        <mat-panel-title class="opportunity__expansion-title">Про подію</mat-panel-title>
                    </mat-expansion-panel-header>
                    <div [innerHTML]="opportunity.baseOpportunity.description"></div>
                </mat-expansion-panel>
            </div>
        </div>
    </div>
</div>
