import { P } from '@angular/cdk/keycodes';
import { DEFAULT_ADDRESS_COUNTRY } from '../../auth/store/auth.utils';
import { IAddress } from '../../core/models/address.model';
import { GenericTypeModel, GenericTypeSource } from '../../core/models/generic-type.model';
import { EMPTY_OPPORTUNITY_REQUEST_SUGGESTION, IOpportunityRequest, IOpportunityRequestSuggestion, OpportunityRequestModel } from '../../core/models/opportunity-request.model';
import { EMPTY_OPPORTUNITY_SUGGESTMENT, IOpportunity, IOpportunitySuggestment, OpportunityModel } from '../../core/models/opportunity.model';
import { IProject, ProjectModel } from '../../core/models/project.model';
import { IUserBase, IUserProfile, UserModel } from '../../core/models/user.model';
import {
    IAdminDataExportResponseData,
    IAdminDataExportsResponseData,
    IAdminGenericTypesListResponseData,
    IAdminOpportunitiesListResponseData,
    IAdminOpportunityRequestResponseData,
    IAdminOpportunityRequestResponseModel,
    IAdminOpportunityRequestsResponseData,
    IAdminOpportunityResponseData,
    IAdminProjectResponseData,
    IAdminProjectsResponseData,
    IAdminUserCreateResponseData,
    IAdminUserProfileResponseData,
    IAdminUserProfilesResponseData,
    IAdminUserResponseData,
    IAdminUsersResponseData,
    ISuggestmentHelpRequest,
    ISuggestmentHelpResponse,
} from '../models/admin.model';
import { PersonAgeDistrinutiontType, ProfileHelper } from '../../core/helpers/profile.helper';
import { CloneHelper } from '../../core/helpers/clone.helper';
import { formatDate } from '@angular/common';
import { utils, writeFile } from 'xlsx-js-style';
import { DataExportModel } from '../../core/models/data-export.model';

const fromSuggestionResponseToIOpportunityRequestSuggestion = (suggestionResponse: IOpportunityRequestSuggestion) => {
    suggestionResponse = suggestionResponse || EMPTY_OPPORTUNITY_REQUEST_SUGGESTION;
    const suggestion: IOpportunityRequestSuggestion = {
        id: suggestionResponse.id,
        status: suggestionResponse.status,
        opportunityRequest: suggestionResponse.opportunityRequest,
        suggested: suggestionResponse.suggested,
        demand: suggestionResponse.demand,
        priority: suggestionResponse.priority,
        solution: suggestionResponse.solution,
    };
    return suggestion;
};

export const fromAdminGenericListResponseToGenericListModel = (response: IAdminGenericTypesListResponseData): GenericTypeModel[] => {
    return response.items
        .map((item) => {
            return new GenericTypeModel({
                id: item.id,
                status: item.status,
                name: item.name,
                description: item.description,
                shortDescription: item.shortDescription,
                source: item.source,
            });
        })
        .sort((a, b) => {
            if (a.baseType.source > b.baseType.source) {
                return 1;
            } else if (a.baseType.source < b.baseType.source) {
                return -1;
            } else return 0;
        });
};

export const fromGenericTypesToOpportunityAvailableCategories = (genericTypes: GenericTypeModel[]) => {
    return genericTypes.filter((type) => type.baseType.source === GenericTypeSource.opportunityAvailableCategories);
};

export const fromGenericTypesToOpportunityTypes = (genericTypes: GenericTypeModel[]) => {
    return genericTypes.filter((type) => type.baseType.source === GenericTypeSource.opportunity);
};

export const fromOpportunitiesToOpportunityModels = (response: IAdminOpportunitiesListResponseData) => {
    const { items } = response;

    const opportunities = items.map((item) => {
        const opportunityData: IOpportunity = {
            ...item,
            types: item.types.map((t) => new GenericTypeModel(t)),
            availableCategories: item.availableCategories.map((t) => new GenericTypeModel(t)),
        };

        const opportunity = new OpportunityModel(opportunityData);
        opportunity.setSuggestment(item.suggestionSettings);
        return opportunity;
    });

    return opportunities;
};

export const fromOpportunityToOpportunityModel = (response: IAdminOpportunityResponseData) => {
    const opportunityData: IOpportunity = {
        ...response,
        types: response.types.map((t) => new GenericTypeModel(t)),
        availableCategories: response.availableCategories.map((t) => new GenericTypeModel(t)),
    };

    const opportunity = new OpportunityModel(opportunityData);
    opportunity.setSuggestment(response.suggestionSettings);
    if (response.project) {
        opportunity.setProject(new ProjectModel(response.project));
    }
    if (response.filters) {
        opportunity.setFilters(response.filters);
    }

    return opportunity;
};

export const fromOpportunityRequestsToOpportunityRequestsModel = (response: IAdminOpportunityRequestsResponseData) => {
    const { items } = response;

    const opportunityRequests = items.map((item) => {
        const opportunityRequestData: IAdminOpportunityRequestResponseModel = {
            ...item,
        };

        const opportunityRequest = new OpportunityRequestModel(opportunityRequestData);
        opportunityRequest.setProfile(item.profile);
        opportunityRequest.setSuggestion(item.suggestion);
        if (item.opportunity) {
            opportunityRequest.setOpportunity(item.opportunity);
        }
        return opportunityRequest;
    });

    return opportunityRequests;
};

export const fromOpportunityRequestsBulkPatchToOpportunityRequestsModel = (response: IAdminOpportunityRequestResponseData[]) => {
    const items = response;

    const opportunityRequests = items.map((item) => {
        const opportunityRequestData: IAdminOpportunityRequestResponseModel = {
            ...item,
        };

        const opportunityRequest = new OpportunityRequestModel(opportunityRequestData);
        opportunityRequest.setProfile(item.profile);
        opportunityRequest.setSuggestion(item.suggestion);
        if (item.opportunity) {
            opportunityRequest.setOpportunity(item.opportunity);
        }
        return opportunityRequest;
    });

    return opportunityRequests;
};

export const fromOSuggestmentHelpResonseToOpportunityRequestsSuggestionsModel = (response: ISuggestmentHelpResponse) => {
    const { demand, solution, priority } = response;
    const suggestions: IOpportunityRequestSuggestion[] = [];

    for (let demandIndex = 0; demandIndex < demand.length; demandIndex++) {
        const suggestion = { ...EMPTY_OPPORTUNITY_REQUEST_SUGGESTION };
        suggestion.demand = demand[demandIndex];
        suggestion.solution = solution[demandIndex];
        suggestion.priority = priority[demandIndex];
        suggestion.suggested = suggestion.solution > 0;

        suggestions.push(suggestion);
    }

    return suggestions;
};

export const fromOpportunityRequestToOpportunityRequestModel = (response: IAdminOpportunityRequestResponseData) => {
    const opportunityRequest = new OpportunityRequestModel(response);
    opportunityRequest.setProfile(response.profile);
    opportunityRequest.setOpportunity(response.opportunity);
    opportunityRequest.setSuggestion(fromSuggestionResponseToIOpportunityRequestSuggestion(response.suggestion));

    return opportunityRequest;
};

export const fromOpportunityToOpportunityCreateRequestModel = (opportunity: OpportunityModel) => {
    const projectId = opportunity.getProject() && opportunity.getProject()?.baseProject.id;
    const opportunityRequestData = {
        ...opportunity.baseOpportunity,
        availableCategories: opportunity.getAvailableCategoriesData(),
        types: opportunity.getTypesData(),
        suggestionSettings: opportunity.getSuggestment(),
        projectId,
    };

    if (!projectId) {
        opportunityRequestData.projectId = null;
    }

    return opportunityRequestData;
};

export const fromOpportunityToOpportunityUpdateRequestModel = (opportunity: OpportunityModel) => {
    const projectId = opportunity.getProject() && opportunity.getProject()?.baseProject.id;
    const opportunityRequestData = {
        ...opportunity.baseOpportunity,
        availableCategories: opportunity.getAvailableCategoriesData(),
        types: opportunity.getTypesData(),
        suggestionSettings: { ...opportunity.getSuggestment() },
        projectId,
        filters: opportunity.getFilters(),
    };

    if (opportunityRequestData.suggestionSettings.opportunityId) {
        delete opportunityRequestData.suggestionSettings.opportunityId;
        delete opportunityRequestData.suggestionSettings.id;
    }

    if (opportunityRequestData.id) {
        delete opportunityRequestData.id;
    }

    if (!projectId) {
        opportunityRequestData.projectId = null;
    }

    return opportunityRequestData;
};

export const fromOpportunityRequestToOpportunityRequestRequestModel = (opportunityRequest: OpportunityRequestModel, keepId: boolean = false) => {
    const opportunityRequestData = {
        ...opportunityRequest.baseRequest,
        suggestion: { ...opportunityRequest.getSuggestion() },
    };

    if (opportunityRequestData.id && !keepId) {
        delete opportunityRequestData.id;
    }

    if (opportunityRequestData.createdAt) {
        delete opportunityRequestData.createdAt;
    }

    if (opportunityRequestData.updatedAt) {
        delete opportunityRequestData.updatedAt;
    }

    if (opportunityRequestData.suggestion && opportunityRequestData.suggestion.id) {
        delete opportunityRequestData.suggestion.id;
    }

    return opportunityRequestData;
};

export const fromOpportunityRequestsToOpportunityRequestRequestsModel = (opportunityRequests: OpportunityRequestModel[]) => {
    const opportunityRequestsData = opportunityRequests.map((opportunityRequest) => {
        return fromOpportunityRequestToOpportunityRequestRequestModel(opportunityRequest, true);
    });

    return opportunityRequestsData;
};

export const fromUserProfileToUserProfileModel = (response: IAdminUserProfileResponseData): IUserProfile => {
    const profile = {
        ...response,
    };

    return profile;
};

export const fromUserProfilesToUserProfilesModel = (response: IAdminUserProfilesResponseData): IUserProfile[] => {
    const { items } = response;

    const useProfiles = items.map((item) => {
        const userProfileData: IAdminUserProfileResponseData = {
            ...item,
        };

        return userProfileData;
    });

    return useProfiles;
};

export const fromUserToUserModel = (response: IAdminUserResponseData): UserModel => {
    const user = new UserModel(response);
    return user;
};

export const fromUserCreateToUserModel = (response: IAdminUserCreateResponseData): UserModel => {
    const { user } = response;
    const newUser = new UserModel(user);
    return newUser;
};

export const fromUsersToUserModels = (response: IAdminUsersResponseData): UserModel[] => {
    const { items } = response;

    const users = items.map((item) => {
        const userData: IAdminUserResponseData = {
            ...item,
        };

        const user = new UserModel(userData);
        if (item.profile) {
            user.setProfile(item.profile);
        }

        return user;
    });

    return users;
};

export const fromProfileToProfileRequest = (profle: IUserProfile): IUserProfile => {
    const updateProfile = CloneHelper.cloneData(profle);
    const address = updateProfile.addresses[0];
    const updateAddress: IAddress = {
        country: DEFAULT_ADDRESS_COUNTRY,
        region: address.region,
        city: address.city,
        address1: address.address1,
        zip: address.zip,
    };
    if (address.id) {
        updateAddress.id = address.id;
    }

    updateProfile.addresses = [updateAddress];
    updateProfile.additionalProfiles?.forEach((profile) => {
        if (!profile.id) {
            delete profile.id;
        }
        delete profile.parent;
    });

    if (updateProfile.id) {
        delete updateProfile.id;
    }
    return updateProfile;
};

export const fromProjectToProjectRequest = (project: IProject): IProject => {
    const updateProject = CloneHelper.cloneData(project);

    if (updateProject.id || updateProject.id === '') {
        delete updateProject.id;
    }
    return updateProject;
};

export const fromProjectToProjectModel = (response: IAdminProjectResponseData): ProjectModel => {
    const project = new ProjectModel(response);
    if (response.opportunities) {
        const opportunities = response.opportunities.map((opp) => {
            return new OpportunityModel(opp);
        });

        project.setOpportunities(opportunities);
    }
    return project;
};

export const fromProjectsToProjectModels = (response: IAdminProjectsResponseData): ProjectModel[] => {
    const { items } = response;

    const projects = items.map((item) => {
        const projectData: IAdminProjectResponseData = {
            ...item,
        };

        const project = new ProjectModel(projectData);
        if (item.opportunities) {
            const opportunities = item.opportunities.map((opp) => {
                return new OpportunityModel(opp);
            });

            project.setOpportunities(opportunities);
        }

        return project;
    });

    return projects;
};

export const fromDataExportToDataExportModel = (response: IAdminDataExportResponseData): DataExportModel => {
    const dataExport = new DataExportModel(response);
    return dataExport;
};

export const fromDataExportsToDataExportsModels = (response: IAdminDataExportsResponseData): DataExportModel[] => {
    const { items } = response;

    const dataExports = items.map((item) => {
        const dataExportData: IAdminDataExportResponseData = {
            ...item,
        };

        const dataExport = new DataExportModel(dataExportData);
        return dataExport;
    });

    return dataExports;
};

export const fromOpportunityAndOpportunityRequestsToSuggestmentHelpRequest = (opportunity: OpportunityModel, oportunityRequests: OpportunityRequestModel[]): ISuggestmentHelpRequest => {
    let table: number[][][] = []; //
    let avail: number = 0;
    let priorityvec: number[][] = [];
    let demandvec: number[][] = [];
    let packetcap: number = 0;
    let minpacket: number = 0;
    let upperbound: number = 0;
    let prioritystyle: number = 0;
    let protocol: number[] = [];
    let proptensor: number[] = [];
    let propfactors: number[] = [];
    let timereceived: number[] = []; //
    let decay: number = 0;
    let api: string = '';

    const suggestmentSettings = opportunity.getSuggestment();
    avail = suggestmentSettings.avail || 1;
    priorityvec = suggestmentSettings.priorityAdjVec || EMPTY_OPPORTUNITY_SUGGESTMENT.priorityAdjVec;
    demandvec = suggestmentSettings.demandVec || EMPTY_OPPORTUNITY_SUGGESTMENT.demandVec;
    packetcap = suggestmentSettings.priorityCap || 1;
    minpacket = suggestmentSettings.minPacket;
    upperbound = suggestmentSettings.upperBound || 1;
    prioritystyle = suggestmentSettings.priorityStyle;
    protocol = suggestmentSettings.protocol.map((p) => parseFloat(p.toString()));
    decay = parseFloat(suggestmentSettings.decay.toString()) || EMPTY_OPPORTUNITY_SUGGESTMENT.decay;
    api = suggestmentSettings.api || EMPTY_OPPORTUNITY_SUGGESTMENT.api;

    table = ProfileHelper.getPersonsSetupFromOpportunityRequests(oportunityRequests);
    timereceived = ProfileHelper.getFamilyHelpLastTimeReceivedRequests(oportunityRequests);

    const suggestmentRequestData = {
        table,
        avail,
        priorityvec,
        demandvec,
        packetcap,
        minpacket,
        upperbound,
        prioritystyle,
        protocol,
        proptensor,
        propfactors,
        timereceived,
        decay,
        api,
    };

    return suggestmentRequestData;
};

export const exportToExcel = (opportunityRequests: OpportunityRequestModel[]) => {
    const excelData: { [key: string]: string }[] = [];

    for (let oppRequestCounter = 0; oppRequestCounter < opportunityRequests.length; oppRequestCounter++) {
        const oppRequest = opportunityRequests[oppRequestCounter];

        const profile = oppRequest.getProfile();
        if (profile) {
            const ageDitribution = ProfileHelper.getPersonAgeDistribution(profile);
            const ageDitributionWithTeporary = ProfileHelper.getPersonAgeDistribution(profile, PersonAgeDistrinutiontType.withTemporaryID);
            const ageDitributionWithInjury = ProfileHelper.getPersonAgeDistribution(profile, PersonAgeDistrinutiontType.withInjury);
            const numberOfInjured = ProfileHelper.getNumberOfInjured(profile);
            const temporaryIDs = ProfileHelper.getUserTemporaryIDNumbers(profile);
            const temporaryIDsString = temporaryIDs.join(', ');
            const numberToTake = oppRequest.getSuggestion()?.solution || 0;
            const isRecommended = oppRequest.getSuggestion()?.suggested ? 'рекомедація' : '';
            const priority = oppRequest.getSuggestion()?.priority.toString() || '0';

            const format = 'dd/MM/yyyy HH-mm';
            const requestDate = oppRequest.baseRequest.createdAt || new Date();
            const locale = 'en-US';
            const formattedDate = formatDate(requestDate, format, locale);
            let zip = '';
            let address = '';

            if (profile && profile.addresses) {
                zip = profile.addresses[0].zip;
                address = `${profile.addresses[0].city} ${profile.addresses[0].region} ${profile.addresses[0].address1}`;
            }

            const excelDataRow = {
                Номер: oppRequest.baseRequest.orderId ? oppRequest.baseRequest.orderId.toString() : '',
                Пріорітет: priority,
                'Дата подачі': formattedDate,
                'Електронна пошта': profile.email || '',
                Прізвище: profile.lastName || '',
                'Ім‘я': profile.firstName || '',
                'Номер телефону': profile.phone || '',
                'Адреса Вашого тимчасового проживання:': address,
                'Поштовий індекс:': zip,
                Паспорт: profile.passport,
                'Номер довідки ВПО': profile.temporaryId,
                'ВПО всіх членів Вашої сімʼї (включаючи Ваш)': temporaryIDsString,
                Рекомендація: isRecommended,

                'Кількість Чоловіків у Вашій сімʼї (віком 18-59 років)': ageDitribution[0].toString(),
                'Кількість Жінок у Вашій сімʼї (віком 18-59 років)': ageDitribution[1].toString(),
                'Кількість Хлопців у Вашій сімʼї (віком до 18 років)': ageDitribution[2].toString(),
                'Уількість Дівчат у Вашій сімʼї (віком до 18 років)': ageDitribution[3].toString(),
                'Кількість Чоловіків у Вашій сімʼї (віком 60+ років)': ageDitribution[4].toString(),
                'Кількість Жінок у Вашій сімʼї (віком 60+ років)': ageDitribution[5].toString(),

                'Кількість Чоловіків у Вашій сімʼї (віком 18-59 років) з ВПО': ageDitributionWithTeporary[0].toString(),
                'Кількість Жінок у Вашій сімʼї (віком 18-59 років) з ВПО': ageDitributionWithTeporary[1].toString(),
                'Кількість Хлопців у Вашій сімʼї (віком до 18 років) з ВПО': ageDitributionWithTeporary[2].toString(),
                'Уількість Дівчат у Вашій сімʼї (віком до 18 років) з ВПО': ageDitributionWithTeporary[3].toString(),
                'Кількість Чоловіків у Вашій сімʼї (віком 60+ років) з ВПО': ageDitributionWithTeporary[4].toString(),
                'Кількість Жінок у Вашій сімʼї (віком 60+ років) з ВПО': ageDitributionWithTeporary[5].toString(),

                'Кількість Чоловіків у Вашій сімʼї (віком 18-59 років) з інвалідністю': ageDitributionWithInjury[0].toString(),
                'Кількість Жінок у Вашій сімʼї (віком 18-59 років) з інвалідністю': ageDitributionWithInjury[1].toString(),
                'Кількість Хлопців у Вашій сімʼї (віком до 18 років) з інвалідністю': ageDitributionWithInjury[2].toString(),
                'Уількість Дівчат у Вашій сімʼї (віком до 18 років) з інвалідністю': ageDitributionWithInjury[3].toString(),
                'Кількість Чоловіків у Вашій сімʼї (віком 60+ років) з інвалідністю': ageDitributionWithInjury[4].toString(),
                'Кількість Жінок у Вашій сімʼї (віком 60+ років) з інвалідністю': ageDitributionWithInjury[5].toString(),

                'Кількість осіб у сімʼї, які мають інвалідність:': numberOfInjured.toString(),
                'Кількість осіб, з числа тих, хто вказаний у данній формі, що мають статус ВПО:': temporaryIDs.length.toString(),
                'Видати посилок:': numberToTake.toString(),
                'Я погоджуюся з тим, що коректне заповнення цієї форми є обовʼязковою умовою для розгляду заявки на отримання гуманітарної допомоги від IAC ISHR:': 'Підтверджую',
                'Я ознайомлений (-на) з тим, що заповнення даної форми не означає обовʼязкового отримання гуманітарної допомоги. Мені відомо, що підтвердженням реєстрації є відповідь співробітників IAC ISHR  на правильно вказану мною електронну адресу':
                    'Підтверджую',
                'Я даю свою згоду на збір та обробку моїх персональних даних:': 'Підтверджую',
            };

            excelData.push(excelDataRow);
        }
    }
    const worksheet = utils.json_to_sheet(excelData);
    const workbook = utils.book_new();

    const wscols = new Array(19).fill({ wpx: 150 }, 0, 32);
    worksheet['!cols'] = wscols;

    const range = utils.decode_range(worksheet['!ref'] ?? '');
    const rowCount = range.e.r;
    const columnCount = range.e.c;

    for (let col = 0; col <= columnCount; col++) {
        const cellRef = utils.encode_cell({ r: 0, c: col });
        // Add center alignment to every cell
        worksheet[cellRef].s = {
            alignment: {
                horizontal: 'center',
                wrapText: true,
            },
            font: { bold: true },
        };
    }

    utils.book_append_sheet(workbook, worksheet, 'Work sheet');
    writeFile(workbook, 'igfm_workbook.xlsx', {
        compression: true,
        type: 'buffer',
        cellStyles: true,
    });
};
